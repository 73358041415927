import React, { useState, useEffect } from 'react';
import { AppBar, Container, Toolbar, IconButton, Typography, Button } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ReportNavBar2 from './ReportNavBar2';
import { getAuth, signOut } from 'firebase/auth';
import LogoTall from './assets/MedicoLogoBlue120.svg';  // 原始Logo
import LogoShort from './assets/LogoShort.svg'; // 较矮的Logo
import UserIcon from './assets/Feather-user.png';
import ShoppingIcon from './assets/Icon-shopping-bag.png';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import { useLocation } from 'react-router-dom';
function NavigationBar({ currentUser, displayName }) {
  // 状态变量用于追踪用户是否向下滚动页面
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const handleOrderDeviceClick = () => {
    navigate('/orderPage');
  };
  const handleSignOut = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      // 登出成功，重定向到首页
      navigate('/');
    } catch (error) {
      console.error('Sign Out Error:', error);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    const handleScroll = () => {
      // 如果滚动超过一定的距离，设置scrolled为true
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 清理函数
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);
  // 根据scrolled状态变量动态设置样式
  const appBarStyle = scrolled ? {
    backgroundColor: 'rgba(250, 255, 249, 0.74)', // 完全透明
    height: '80px', // 高度为80px
    transition: 'all 0.01s ease-in-out', // 平滑过渡效果
  } : {
    backgroundColor: 'rgba(255, 255, 255, 1)', // 60%透明度
    height: '120px', // 自动高度
    transition: 'all 0.01s ease-in-out', // 平滑过渡效果
  };
  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate('/'); // 点击 logo 时导航回主页
  };
  const handleSignInClick = () => {
    if (currentUser) {
      navigate('/user'); // 如果用户已登录，导航到用户页面
    } else {
      navigate('/login'); // 如果用户未登录，导航到登录/注册页面
    }
  };
  // const handleCreateAccountClick = () => {
  //   navigate('/register');
  // };

  const handleSupportClick = () => {
    navigate('/contactus');
  };
  const handleContectUsClick = () => {
    navigate('/contactus');
  };

  // 判断当前路径是否是 "/user"
  const shouldRenderReportNavBar = (location.pathname === '/user' || location.pathname === '/reports'
    || location.pathname === '/user-prescription' || location.pathname === '/diagnose' || location.pathname === '/rawData');
  return (
    <AppBar position="fixed" sx={appBarStyle}>

      {/* <AppBar position="fixed" sx={{ bgcolor: 'transparent', backgroundSize: 'cover', backgroundPosition: 'center' }}> */}
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="logo"
            onClick={handleHomeClick}

          >
            {/* <img src={scrolled ? LogoShort : LogoTall} alt="logo" style={{marginTop:'10px', marginBottom:'10px', height:'7.4vh'}}/>  */}
            <img
              src={scrolled ? LogoShort : LogoTall}
              alt="logo"
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                height: scrolled ? '40px' : '7.4vh'  // 根据 scrolled 的状态动态设置高度
              }}
            />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1, cursor: 'pointer' }} onClick={handleHomeClick}>

          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: 'transparent', // 用您想要的 "mint" 颜色的十六进制代码代替
              color: '#003A57', // 设置文字颜色
              fontSize: '18px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#003A57', // 悬停状态的更深 "mint" 颜色
                color: '#ffffff', // 设置文字颜色
                fontSize: '18px',
                boxShadow: 'none',
              },
              marginRight: 2
            }}
            onClick={handleHomeClick}
          >
            Our story
          </Button>
          {/* <Button variant="contained" sx={{
            backgroundColor: '#00f98a', // 用您想要的 "mint" 颜色的十六进制代码代替
            '&:hover': {
              backgroundColor: '#b04eff', // 悬停状态的更深 "mint" 颜色
            },
            marginRight: 2
          }} onClick={handleCreateAccountClick}>Create Account</Button> */}
          <Button variant="contained" sx={{
            backgroundColor: 'transparent', // 用您想要的 "mint" 颜色的十六进制代码代替
            color: '#003A57', // 设置文字颜色
            fontSize: '18px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#003A57', // 悬停状态的更深 "mint" 颜色
              color: '#ffffff', // 设置文字颜色
              fontSize: '18px',
              boxShadow: 'none',
            },
            marginRight: 2
          }} onClick={handleSupportClick}>Support</Button>
          <Button variant="contained" sx={{
            backgroundColor: 'transparent', // 用您想要的 "mint" 颜色的十六进制代码代替
            color: '#003A57', // 设置文字颜色
            fontSize: '18px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#003A57', // 悬停状态的更深 "mint" 颜色
              color: '#ffffff', // 设置文字颜色
              fontSize: '18px',
              boxShadow: 'none',
            },
            marginRight: 2
          }} onClick={handleContectUsClick}>Contact Us</Button>
          {currentUser ? (
            <Button variant="contained"
              sx={{
                backgroundColor: 'transparent',
                color: '#0192D8',
                fontSize: '18px',
                boxShadow: 'none',
                border: '2px solid #cccccc', // 灰色边框
                borderRadius: '8px', // 圆角
                '&:hover': {
                  backgroundColor: '#0192D8',
                  color: '#ffffff',
                  fontSize: '18px',
                  boxShadow: 'none',
                },
                marginRight: 2
              }}
              onClick={handleSignInClick}> Hello, {displayName}</Button>
          ) : (
            <IconButton
              onClick={handleSignInClick}
              sx={{
                backgroundColor: 'transparent', // 背景透明
                '&:hover': {
                  backgroundColor: 'rgba(0, 58, 87, 0.2)', // 悬停时背景轻微变化
                },
                marginLeft: 4,
                marginRight: 2,
                padding: 0, // 去除内边距，确保图标大小合适
              }}
            >
              <img src={UserIcon} alt="User Icon" style={{ width: 40, height: 40 }} /> {/* 你可以根据需要调整宽度和高度 */}
            </IconButton>
          )}
          {currentUser ? (
            <Button variant="contained"
              sx={{
                backgroundColor: '#0192D8',
                color: '#ffffff',
                fontSize: '18px',
                boxShadow: 'none',
                border: '2px solid #cccccc', // 灰色边框
                borderRadius: '8px', // 圆角
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#003A57',
                  fontSize: '18px',
                  boxShadow: 'none',
                },
                marginRight: 2

              }}
              onClick={handleSignOut}
              endIcon={<LockIcon />} // 添加锁头图标
            > Log Out</Button>
          ) : (
            <div style={{ width: '0' }}></div>
          )}
          <IconButton
            // onClick={}
            sx={{
              backgroundColor: 'transparent', // 背景透明
              '&:hover': {
                backgroundColor: 'rgba(0, 58, 87, 0.2)', // 悬停时背景轻微变化
              },
              marginLeft: 2,
              marginRight: 2,
              padding: 0, // 去除内边距，确保图标大小合适
            }}
          >
            <img src={ShoppingIcon} alt="Shopping Icon" style={{ width: 40, height: 40 }} /> {/* 你可以根据需要调整宽度和高度 */}
          </IconButton>
          <Button
            onClick={handleOrderDeviceClick}
            variant="contained"
            sx={{
              backgroundColor: '#003A57', // 设置背景颜色为 #003A57
              color: '#ffffff', // 设置文字颜色为白色
              fontSize: '18px',
              boxShadow: 'none', // 去除阴影
              borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
              padding: '10px 20px', // 添加适当的内边距
              '&:hover': {
                backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                color: '#ffffff', // 悬停时文字颜色仍然为白色
                boxShadow: 'none', // 确保悬停时也没有阴影
              },
              marginLeft: 4,
              marginRight: 2,
            }}
            endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
          >
            PRE-ORDER NOW
          </Button>
        </Toolbar>
      </Container>
      {currentUser && shouldRenderReportNavBar ? (
        <ReportNavBar2 currentPath={location.pathname} />
      ) : (
        <div style={{ width: '0' }}></div>
      )}
      <IconButton
        onClick={scrollToTop}
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '20px',
          backgroundColor: '#b04eff',
          color: 'white',
          borderRadius: '50%',
        }}
      >
        <KeyboardDoubleArrowUpIcon />
      </IconButton>
    </AppBar>
  );
}

export default NavigationBar;