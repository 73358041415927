import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Typography, Button, TextField } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import contactUsBG from './assets/contactUsBG.png';


// import OrderBG1 from './assets/OrderBG1.png';
function ContactPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };
    const handleQuery = async () => {
        if (!email || !firstName || !lastName || !phoneNumber || !message) {
            alert('All fields must be filled out.');
            return;
        }
        if (!validateEmail(email)) {
            alert('Please enter a valid email address.');
            return;
        }
        // 验证是否是字符串
        if (typeof firstName !== 'string' || typeof lastName !== 'string' || typeof message !== 'string') {
            alert('First Name, Last Name, and Message must be valid text.');
            return;
        }
        // 验证电话号码是否只包含数字
        const phoneNumberPattern = /^\d+$/; // 正则表达式，确保电话号码只包含数字
        if (!phoneNumberPattern.test(phoneNumber)) {
            alert('Please enter a valid phone number with digits only.');
            return;
        }
        const device = navigator.userAgent;
    const subject = 'Customer Inquiry';
    const body = `System sent automatically.%0D%0A%0D%0ASent time: ${new Date().toLocaleString()}%0D%0ASent from: ${device}%0D%0AConsultation content: general (Subscribe for Updates)%0D%0A%0D%0AUser Information:%0D%0AFirst Name: ${firstName}%0D%0ALast Name: ${lastName}%0D%0APhone Number: ${phoneNumber}%0D%0A%0D%0AMessage:%0D%0A${message}`;
    const mailtoLink = `mailto:terry@orthomedtech.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;

    };
    // const navigate = useNavigate();
    // 在组件加载时，自动滚动到页面顶部
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <div id="Page0" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '120px', backgroundColor: '#DDF4FF', height: '38vh', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: `url(${contactUsBG})` }}>
                    <div style={{ margin: 'auto' }}>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                // fontFamily: 'Montserrat',
                                color: '#003A57',
                                fontWeight: 'bold',
                                fontSize: '7vh',
                                textAlign: 'center',
                                width: '100%',

                            }}
                        >
                            Contact Us
                        </Typography>

                    </div>

                </div>
            </div>
            <div id="Page1" style={{ width: '100%' }}>
                <div style={{ backgroundColor: '#DDF4FF', paddingLeft: '100px', paddingRight: '100px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '60vw center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0px', paddingLeft: '0px', paddingRight: '0px', paddingTop: '100px' }}>
                        <div style={{ flexBasis: '50%' }}>

                            <Typography
                                variant="h2"
                                component="h1"
                                gutterBottom
                                style={{
                                    // fontFamily: 'Montserrat',
                                    color: '#003A57',
                                    fontWeight: 'bold',
                                    fontSize: '4vh',
                                    textAlign: 'left',
                                    width: '100%',
                                    marginTop: '30px'
                                }}
                            >
                                Reach Us
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '30px' }}>
                                <AccessTimeIcon style={{ fontSize: 40, color: '#0000EE', marginRight: '20px' }} alt="Timer Logo" />
                                <div style={{ flexBasis: '90%' }}>
                                    <Typography
                                        variant="h2"
                                        component="h1"
                                        gutterBottom
                                        style={{
                                            // fontFamily: 'Montserrat',
                                            color: '#003A57',
                                            fontWeight: 'bold',
                                            fontSize: '3.5vh',
                                            textAlign: 'left',
                                            width: '100%'
                                        }}
                                    >
                                        A personalized view of
                                        your whole health
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h1"
                                        gutterBottom
                                        style={{
                                            // fontFamily: 'Montserrat',
                                            color: '#003A57',
                                            fontWeight: 'bold',
                                            fontSize: '2.5vh',
                                            textAlign: 'left',
                                            width: '100%'
                                        }}
                                    >
                                        Monday - Friday
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h1"
                                        gutterBottom
                                        style={{
                                            // fontFamily: 'Montserrat',
                                            color: '#003A57',
                                            fontWeight: 'bold',
                                            fontSize: '2.5vh',
                                            textAlign: 'left',
                                            width: '100%'
                                        }}
                                    >
                                        9:00AM - 6:00PM
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h1"
                                        gutterBottom
                                        style={{
                                            // fontFamily: 'Montserrat',
                                            color: '#003A57',
                                            fontWeight: 'bold',
                                            fontSize: '2.5vh',
                                            textAlign: 'left',
                                            width: '100%'
                                        }}
                                    >
                                        Saturday - Sunday
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h1"
                                        gutterBottom
                                        style={{
                                            // fontFamily: 'Montserrat',
                                            color: '#003A57',
                                            fontWeight: 'bold',
                                            fontSize: '2.5vh',
                                            textAlign: 'left',
                                            width: '100%'
                                        }}
                                    >
                                        Closed
                                    </Typography>
                                </div>

                            </div>


                        </div>
                        <div style={{ flexBasis: '50%' }}>

                            <Typography
                                variant="h2"
                                component="h1"
                                gutterBottom
                                style={{
                                    // fontFamily: 'Montserrat',
                                    color: '#003A57',
                                    fontWeight: 'bold',
                                    fontSize: '4vh',
                                    textAlign: 'left',
                                    width: '100%',
                                    marginTop: '30px'
                                }}
                            >
                                Inquiry Form
                            </Typography>
                            <div style={{ flexBasis: '50%', display: 'flex', flexDirection: 'column' }}>
                                <TextField label="First Name" variant="outlined" onChange={(e) => setFirstName(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
                                    style: {
                                        color: '#0291D4', // 设置输入框文字颜色
                                    },
                                }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#0291D4', // 设置标签文字颜色
                                        },
                                    }} />
                                <TextField label="Last Name" variant="outlined" onChange={(e) => setLastName(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
                                    style: {
                                        color: '#0291D4', // 设置输入框文字颜色
                                    },
                                }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#0291D4', // 设置标签文字颜色
                                        },
                                    }} />
                                <TextField label="Email" type="email" variant="outlined" onChange={(e) => setEmail(e.target.value)} style={{ marginBottom: '20px' }} InputProps={{
                                    style: {
                                        color: '#0291D4', // 设置输入框文字颜色
                                    },
                                }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#0291D4', // 设置标签文字颜色
                                        },
                                    }} />
                                <TextField
                                    label="Phone Number"
                                    type="tel"
                                    variant="outlined"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    style={{ marginBottom: '20px' }}
                                    InputProps={{
                                        style: {
                                            color: '#0291D4', // 设置输入框文字颜色
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#0291D4', // 设置标签文字颜色
                                        },
                                    }}
                                    placeholder="1-123-123-1234" // 可选：设置占位符提示电话号码格式
                                />
                                <TextField
                                    label="Message"
                                    variant="outlined"
                                    multiline
                                    rows={4} // 控制默认显示的行数
                                    onChange={(e) => setMessage(e.target.value)}
                                    style={{ marginBottom: '20px' }}
                                    InputProps={{
                                        style: {
                                            color: '#0291D4', // 设置输入框文字颜色
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#0291D4', // 设置标签文字颜色
                                        },
                                    }}
                                    placeholder="Enter your message here..." // 可选：提示用户输入长文本
                                />
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flexBasis: '70%' }} ></div>
                                    <Button
                                        variant="contained"
                                        onClick={handleQuery}
                                        sx={{
                                            marginBottom:'60px',
                                            flexBasis: '30%',
                                            width: '100%',
                                            backgroundColor: '#0192d8', // 设置背景颜色为 #003A57
                                            color: '#ffffff', // 设置文字颜色为白色
                                            fontSize: '18px',
                                            boxShadow: 'none', // 去除阴影
                                            borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                                            padding: '12px 34px', // 添加适当的内边距
                                            '&:hover': {
                                                backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                                                color: '#ffffff', // 悬停时文字颜色仍然为白色
                                                boxShadow: 'none', // 确保悬停时也没有阴影
                                            },
                                        }}
                                    >
                                        SUBMIT
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
