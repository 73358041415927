// import Typography from '@mui/material/Typography';
import React, { useRef,useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Paper, Box } from '@mui/material';
// import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import MainBG1 from './assets/mainBG1v2.png';
import MainBG2 from './assets/mainBG2.png';
import MainBG3 from './assets/MainBG3.3.png';
import deviceIMG1 from './assets/deviceIMG1.png';
// import appDeviceIMG from './assets/app+device.png';
import appDevice3IMG from './assets/app+device3.png';
import lineRightUp from './assets/lineRightUp.png';
import lineLeftUp from './assets/lineLeftUp.png';
import lineLeft from './assets/lineLeft.png';
import lineRight from './assets/lineRight.png';
import GreenDotCheck from './assets/GreenDotCheck.png';
// import EllipselOGO1 from './assets/EllipselOGO1.png';
// import EllipselOGO2 from './assets/EllipselOGO2.png';
// import EllipselOGO3 from './assets/EllipselOGO3.png';
// import EllipselOGO4 from './assets/EllipselOGO4.png';
// import EllipselOGO5 from './assets/EllipselOGO5.png';
// import EllipselOGO6 from './assets/EllipselOGO6.png';
// import EllipselOGO7 from './assets/EllipselOGO7.png';
// import EllipselOGO8 from './assets/EllipselOGO8.png';
import botLogo1 from './assets/botLogo1.png';
import botLogo2 from './assets/botLogo2.png';
import botLogo3 from './assets/botLogo3.png';
import botLogo4 from './assets/botLogo4.png';
import botLogo5 from './assets/botLogo5.png';
import botLogo6 from './assets/botLogo6.png';
import downloadAppleIMG from './assets/downloadApple.png';
import downloadGoogleIMG from './assets/downGoogle.png';
import { Container, Typography, Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from './assets/arrowdown.png';
import yogaPage2 from './assets/yogaPage2.png';

import HomeSlider1 from './assets/HomeSlider1.png';
import HomeSlider2 from './assets/HomeSlider2.png';
import HomeSlider3 from './assets/HomeSlider3.png';
import HomeSlider4 from './assets/HomeSlider4.png';

import arrowBlueLeft from './assets/arrowBlueLeft.png';
import arrowBlueRight from './assets/arrowBlueRight.png';
import howItWork from './assets/howItWork.png';
function HomePage() {

  const navigate = useNavigate();

  const carouselRef = useRef(null); // 创建 Carousel 的引用
  const handleHomeClick = () => {
    const page3Element = document.getElementById('Page3');
    if (page3Element) {
      page3Element.scrollIntoView({ behavior: 'smooth' }); // 滚动到Page2，平滑滚动效果
    }
  };
  //slide view 
  const items = [
    {
      index: 1,
      name: "HEART HEALTH",
      description: "24/7 monitoring, providing continuous Heart Rate, real time ECG and Blood Oxygen Saturation (SpO2) data for valuable health insights. ",
      imgPath: HomeSlider1
    },
    {
      index: 2,
      name: "SLEEP QUALITY",
      description: "Identify ways to get a more restful sleep with detailed analysis of your sleep cycles and overnight health vitals.",
      imgPath: HomeSlider2
    },
    {
      index: 3,
      name: "ACTIVITY TRACKING",
      description: "24/7 monitoring of your activity trails, constantly recording steps taken and calories burned, helping you to make better adjustments to your fitness plan.",
      imgPath: HomeSlider3
    },
    {
      index: 4,
      name: "FALL DETECTION",
      description: "Gyroscope sensor monitors your body position for fall detection and alert your love ones with real time alert system built in the app. ",
      imgPath: HomeSlider4
    }
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,  // 显示3个项目
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,  // 显示2个项目
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,  // 显示1个项目
    },
  };
  const CustomLeftArrow = () => (
    <Button
      onClick={() => carouselRef.current.previous()} // 调用 previous 方法
      sx={{
        borderRadius: '50%',
        minWidth: '40px',
        minHeight: '40px',
        zIndex: 10,
        '& img': {
          transition: 'opacity 0.3s ease', // 添加平滑过渡效果
        },
        '&:hover img': {
          opacity: 0.7, // 在 hover 时降低透明度，或者直接替换图片
          // content: `url(${arrowBlueLeftHover})`, // 在 hover 时替换图片
        },
      }}
    >
      <img src={arrowBlueLeft} alt="Left Arrow" style={{ width: "40px" }} />
    </Button>
  );

  const CustomRightArrow = () => (
    <Button
      onClick={() => carouselRef.current.next()} // Call the next method
      sx={{
        borderRadius: '50%',
        minWidth: '40px',
        minHeight: '40px',
        zIndex: 10,
        '& img': {
          transition: 'opacity 0.3s ease', // 添加平滑过渡效果
        },
        '&:hover img': {
          opacity: 0.7, // 在 hover 时降低透明度，或者直接替换图片
          // content: `url(${arrowBlueLeftHover})`, // 在 hover 时替换图片
        },
      }}
    >
      <img src={arrowBlueRight} alt="Left Arrow" style={{ width: "40px" }}></img>
    </Button>
  );
  // const handleCreateAccountClick = () => {
  //   navigate('/register');
  // };
  // const handleDeviceImageClick = () => {
  //   const targetDiv = document.getElementById('Page3');
  //   if (targetDiv) {
  //     targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  // const handleAppImageClick = () => {
  //   const targetDiv = document.getElementById('Page2');
  //   if (targetDiv) {
  //     targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // };
  const handleScrollToPage2 = () => {
    const page2Element = document.getElementById('Page2');
    if (page2Element) {
      page2Element.scrollIntoView({ behavior: 'smooth' }); // 滚动到Page2，平滑滚动效果
    }
  };
  const handleOrderDeviceClick = () => {
    navigate('/orderPage');
  };
  const handleDownloadAppleImageClick = () => {
    window.open('https://apps.apple.com/app/vitalitus/id1583984803', '_blank');
  };
  const handleDownloadGoogleImageClick = () => {
    navigate('/orderPage');
  };
  // 在组件加载时，自动滚动到页面顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div id="Page1" style={{ backgroundImage: `url(${MainBG1})`, height: '95vh', backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', position: 'relative', top: '50px', }}>
        {/* page1 */}


        <Container component="main" maxWidth="false" style={{ width: '43%', height: '90%', position: 'absolute', paddingTop: '160px', paddingLeft: '70px', paddingRight: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <Typography paragraph style={{ 
              // fontFamily: 'Montserrat', 
              fontWeight: 500, fontSize: '20px', color: '#003A57', textAlign: "left", width: '100%' }}>
              INTRODUCING VITALMARK
            </Typography>
          </div>
          <Typography variant="h2" component="h1" gutterBottom style={{ 
            // fontFamily: 'Montserrat',
             color: '#003A57', fontWeight: 'bold', fontSize: '4vh', textAlign: "left", width: '100%' }}>
            THE FUTURE OF REMOTE EMERGENCY MONITORING IS HERE
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <Typography paragraph style={{ 
              // fontFamily: 'Montserrat', 
              fontWeight: 500, fontSize: '2.5vh', color: '#003A57', textAlign: "left", width: '100%', marginTop: '40px' }}>
              VitalMark empowers individuals, families, doctors and patients with real-time health vital monitoring such as ECG, heart rate, blood oxygen, respiratory rate, body temperature, activity level (including step count), and fall detection.
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'left', marginTop: "50px" }}>
            <div style={{ display: 'flex', justifyContent: 'left', width: '70%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Button
                  variant="contained"
                  onClick={handleOrderDeviceClick}
                  sx={{
                    backgroundColor: '#003A57', // 设置背景颜色为 #003A57
                    color: '#ffffff', // 设置文字颜色为白色
                    fontSize: '18px',
                    boxShadow: 'none', // 去除阴影
                    borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                    padding: '12px 34px', // 添加适当的内边距
                    '&:hover': {
                      backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                      color: '#ffffff', // 悬停时文字颜色仍然为白色
                      boxShadow: 'none', // 确保悬停时也没有阴影
                    },
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                  endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
                >
                  PRE ORDER
                </Button>
                <div style={{ display: 'flex', justifyContent: "flex-start", marginTop: '10px' }}>
                  {/* DIV2.1 */}

                  <img
                    src={downloadAppleIMG}
                    alt="Download Apple"
                    style={{ width: '200px', cursor: 'pointer', marginTop: '10px' }}
                    onClick={handleDownloadAppleImageClick}
                  />
                  <img
                    src={downloadGoogleIMG}
                    alt="Download Google"
                    style={{ width: '200px', cursor: 'pointer', marginTop: '10px' }}
                    onClick={handleDownloadGoogleImageClick}
                  />
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={deviceIMG1}
                    alt="Device"
                    style={{ width: '200px', cursor: 'pointer', marginBottom: '10px' }}  // 在图片下方添加 margin
                    onClick={handleDeviceImageClick}
                  />
                  <div style={{
                    width: '60%',
                    height: '20px',
                    backgroundColor: 'rgba(0,0,0,0.15)',
                    borderRadius: '50%',
                    marginTop: '0px', // 调整阴影距离图片的位置，考虑到图片的 marginBottom
                  }} />
                </div> */}
                {/* <div style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  backgroundColor: 'rgba(155, 155, 155, 0.2)', // 白色背景，50% 透明度
                  padding: '20px'
                }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    VitalMark MK-I
                  </Typography>
                  <Typography variant="body1">
                    40mm * 40 mm with patch sensor chipset
                  </Typography>

                  <div style={{ textAlign: 'left', display: 'inline-block', marginLeft: 'calc(-70% + 100px)' }}>
                    <ul style={{ textAlign: 'left' }}>
                      <li style={{ fontSize: '12px' }}>Real-time SPO2 Monitoring</li>
                      <li style={{ fontSize: '12px' }}>Average Heart Rate Tracking</li>
                      <li style={{ fontSize: '12px' }}>Sleep Quality Analysis</li>
                      <li style={{ fontSize: '12px' }}>Fall Detection System</li>
                      <li style={{ fontSize: '12px' }}>ECG Functionality</li>
                      <li style={{ fontSize: '12px' }}>......</li>
                    </ul>
                  </div>
                </div> */}
              </div>

              {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleCreateAccountClick}
                  sx={{
                    borderRadius: '20px',
                    marginBottom: '50px',
                    backgroundColor: '#b04eff',
                    '&:hover': {
                      backgroundColor: '#00f98a',
                    },
                    fontSize: '18px', // 增大字体尺寸
                    padding: '12px 24px' // 增加按钮填充以放大按钮
                  }}
                >
                  Create Account
                </Button>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <img
                    src={appDeviceIMG}
                    alt="Device"
                    style={{ width: '200px', cursor: 'pointer', marginBottom: '20px' }}  // 在图片下方添加 margin
                    onClick={handleAppImageClick}
                  />


                </div>
                <div style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  backgroundColor: 'rgba(155, 155, 155, 0.2)', // 白色背景，50% 透明度
                  padding: '20px'
                }}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Introducing the Vitalitus App
                  </Typography>
                  <Typography variant="body1">
                    Compatible with iOS and Android devices
                  </Typography>

                  <div style={{ textAlign: 'left', display: 'inline-block', marginLeft: 'calc(-0.1% )' }}>
                    <ul style={{ textAlign: 'left' }}>
                      <li style={{ fontSize: '12px' }}>Intelligent health data analytics</li>
                      <li style={{ fontSize: '12px' }}>Notifications for abnormal health indicators</li>
                      <li style={{ fontSize: '12px' }}>Backup health report syncing with cloud storage</li>
                      <li style={{ fontSize: '12px' }}>Syncs with smartwatches for health tracking</li>
                      <li style={{ fontSize: '12px' }}>Sleep-Assisting Ambient Sounds</li>
                      <li style={{ fontSize: '12px' }}>......</li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>



        </Container>
        <div id='middilescrowBtn' style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }}>
          <Button
            onClick={handleScrollToPage2} // 添加点击事件
            endIcon={
              <img src={ArrowDownwardIcon} alt="Arrow Down" style={{ width: '70%', animation: 'moveUpDown 1.5s infinite' }} />
            }
          >
          </Button>
          <style>
            {`
              @keyframes moveUpDown {
                0%, 100% {
                  transform: translateY(0);  // 初始位置
                }
                50% {
                  transform: translateY(10px);  // 向下移动 10px
                }
              }
            `}
          </style>
        </div>
      </div>
      <div id="Page2" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* page2 */}

        <Container component="main" maxWidth="false" style={{ width: '94%', paddingTop: '100px', display: 'flex' }}>
          <div >
            <img src={yogaPage2} style={{ width: '36.2vw' }} alt='yogaPage2' />
          </div>
          <div style={{ paddingLeft: "50px" }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              style={{
                // fontFamily: 'Montserrat',
                color: '#003A57',
                fontWeight: 'bold',
                fontSize: '4vh',
                textAlign: 'left',
                width: '100%',
                textTransform: 'uppercase', // 将内容全部大写
                marginTop: '30px'
              }}
            >
              A personalized view<br />
              of your whole health
            </Typography>
            <Typography paragraph style={{ 
              // fontFamily: 'Montserrat', 
              fontWeight: 500, fontSize: '2.5vh', color: '#003A57', textAlign: "left", width: '100%', marginTop: '80px' }}>
              THE SMARTEST TECHNOLOGY UNLIKE ANY OTHER
            </Typography>
            <Typography component="ul" style={{ 
              // fontFamily: 'Montserrat', 
              fontSize: '2.5vh', color: '#003A57', listStyleType: 'disc', marginLeft: '0', lineHeight: '60px', marginBottom: '60px' }}>
              <li>Heart Health, Blood Oxygen Level & Fall Detection in one device</li>
              <li>Remote Patient Monitoring</li>
              <li>Login portals for Patient Family & Doctors</li>
              <li>Real-time warnings & alerts</li>
            </Typography>
            <Button
              variant="contained"
              onClick={handleHomeClick}
              sx={{
                backgroundColor: '#003A57', // 设置背景颜色为 #003A57
                color: '#ffffff', // 设置文字颜色为白色
                fontSize: '18px',
                boxShadow: 'none', // 去除阴影
                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                padding: '12px 34px', // 添加适当的内边距
                '&:hover': {
                  backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                  color: '#ffffff', // 悬停时文字颜色仍然为白色
                  boxShadow: 'none', // 确保悬停时也没有阴影
                },
                marginLeft: 4,
                marginRight: 2,
              }}
              endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
            >
              Our story
            </Button>
          </div>
        </Container>
        {/* <div style={{ width: '90%', alignItems: 'center', margin: '0 auto' }}>
          <Carousel
            interval={4000} // 设置时间为 4 秒
            animation="slide" // 设置动画为滑动
            indicators={false} // 如果不需要显示指示点，设为 false
            navButtonsAlwaysVisible={true} // 总是显示导航按钮
            sx={{
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.1)', // 你可以在这里添加更多样式
              display: 'flex', // 确保容器内容为 flex 布局
              justifyContent: 'center', // 居中对齐
            }}
          >
            {items.map((item) => (
              <Paper
                key={item.id} // 使用唯一标识符 id 作为 key
                sx={{
                  flex: '1 0 33%', // 设置每个子项占据 1/3 宽度
                  padding: '0 10px', // 添加左右内边距
                  boxSizing: 'border-box',
                }}
              >
                <img src={item.imgPath} alt={item.name} style={{ width: "30vw", height: "auto" }} />
                <h2>{item.name}</h2>
                <p>{item.description}</p>
              </Paper>
            ))}
          </Carousel>
        </div> */}

      </div>
      <div id="Page3" style={{ backgroundImage: `url(${MainBG2})`, height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center center', width: '100%', }}>
        {/* page3 */}
        {/* <Container component="main" maxWidth="false" style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingTop: '100px' }}>
          <div style={{ textAlign: 'center', marginBottom: '80px' }}>
            <Typography variant="h1" component="h2" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '70px' }}>
              FEATURES<br />

            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1, margin: '40px' }}>
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO1}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    3 axis accelerometer
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    This sensor measures acceleration in three dimensions, enabling precise tracking of movement and orientation. Ideal for monitoring daily activities and exercise.
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO2}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Blood oxygen sensor
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Measures the oxygen saturation level in your blood, providing critical insights into your respiratory and cardiovascular health.
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO3}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Gyroscope sensor
                    (for orientation)
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Detects orientation and rotation, offering enhanced motion tracking capabilities, especially useful in sports and fitness applications.
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO4}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    ECG in app
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    The app provides electrocardiogram functionalities, allowing you to monitor your heart’s electrical activity and detect any irregularities.
                  </Typography>
                </div>

              </div>
            </div>
            <div style={{ height: '100%', width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}></div>
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO5}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Step counter
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Accurately counts your steps throughout the day, providing valuable data for tracking your physical activity levels and setting fitness goals.
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO6}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Heartrate sensor
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Continuously monitors your heart rate, offering vital information about your overall cardiac health and response to physical activity.
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO7}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Fall detection
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    This feature automatically detects if the wearer has taken a hard fall, potentially triggering an alert for immediate assistance. Essential for elderly users or those with mobility issues.
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={EllipselOGO8}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    0-16kHz microphone
                    for sleep audio
                  </Typography>
                  <Typography variant="p" component="p" style={{ fontFamily: 'Montserrat', fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Captures a wide range of audio frequencies during sleep, which can be used to analyze sleep patterns and detect potential sleep disturbances.
                  </Typography>
                </div>

              </div>
            </div>
          </div>

        </Container> */}
        <div style={{ width: '70%', paddingTop: '100px', alignItems: 'center', margin: '0 auto' }}>
          <Carousel
            ref={carouselRef} // 附加引用到 Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={4000}
            showDots={false}
            arrows={false} // 关闭默认的箭头
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            sx={{
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {items.map((item) => (
              <Box key={item.index} sx={{ padding: '20px 10px', boxSizing: 'border-box' }}>
                <Paper elevation={1} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#F7F8F8', height: "64vh" }}>
                  <img src={item.imgPath} alt={item.name} style={{ width: "100%", height: "auto" }} />
                  <Typography variant="h6" component="h2" sx={{ textAlign: 'start', color: '#003A57' }} >{item.name}</Typography>
                  <Typography variant="body2" sx={{ textAlign: 'start', color: '#003A57' }}>{item.description}</Typography>
                </Paper>
              </Box>
            ))}
          </Carousel>
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <Button
              variant="contained"
              onClick={handleOrderDeviceClick}
              sx={{
                backgroundColor: '#003A57', // 设置背景颜色为 #003A57
                color: '#ffffff', // 设置文字颜色为白色
                fontSize: '18px',
                boxShadow: 'none', // 去除阴影
                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                padding: '12px 34px', // 添加适当的内边距
                '&:hover': {
                  backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                  color: '#ffffff', // 悬停时文字颜色仍然为白色
                  boxShadow: 'none', // 确保悬停时也没有阴影
                },
                marginLeft: 4,
                marginRight: 2,
              }}
              endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
            >
              PRE ORDER
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: "400px" }}>
            <CustomLeftArrow />
            <CustomRightArrow />
          </div>
        </div>
      </div>

      <div id="Page4" style={{ width: '100%', height: '100vh', margin: '0 auto', backgroundColor: '#DDF4FF' }}>
        {/* DIV1 */}
        <div style={{ display: "flex", justifyContent: 'center', textAlign: 'center', padding: '60px', paddingTop: '100px' }}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            style={{
              // fontFamily: 'Montserrat',
              color: '#003A57',
              fontWeight: 'bold',
              fontSize: '4vh',
              width: '100%',
              textTransform: 'uppercase', // 将内容全部大写
              marginTop: '30px'
            }}
          >
            How It Works
          </Typography>
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
          <img src={howItWork} alt='howItWork'></img>

        </div>
      </div>
      <div id="Page5"  >
        {/* page5 */}
        <Container component="main" maxWidth="false" style={{ width: '80%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingTop: '100px' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              style={{
                // fontFamily: 'Montserrat',
                color: '#003A57',
                fontWeight: 'bold',
                fontSize: '4vh',
                width: '100%',
                textTransform: 'uppercase', // 将内容全部大写
                marginTop: '30px'
              }}
            >
              SPECIFICATIONS

            </Typography>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1, margin: '40px 40px 20px 40px' }}>
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: '40px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={botLogo1}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Weight
                  </Typography>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    0.29 lb <br />
                    (130g) including batteries
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={botLogo4}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Battery
                  </Typography>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    500mAh Lithium Polymer battery (estimated 1 week battery life)
                  </Typography>
                </div>

              </div>
            </div>
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: '40px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={botLogo2}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Device Dimensions
                  </Typography>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Size: 53mm <br />
                    case diameter
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={botLogo5}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Inside
                  </Typography>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    ARM Cortex-M4 onboard microcontroller
                  </Typography>
                </div>

              </div>

            </div>
            <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: '40px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={botLogo3}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Water Resistant
                  </Typography>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat',
                     fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    splash and water resistant,
                    rated IP65
                  </Typography>
                </div>

              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={botLogo6}
                  alt="GreenDotCheck"
                  style={{ width: '100px', height: '100px' }}
                />
                <div>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'bold', fontSize: '40px', marginLeft: '20px', marginBottom: '20px', color: '#6c9c2e' }}>
                    Wireless Connection
                  </Typography>
                  <Typography variant="p" component="p" style={{ 
                    // fontFamily: 'Montserrat', 
                    fontWeight: 'Medium', fontSize: '30px', marginLeft: '20px' }}>
                    Bluetooth Low Energy (BLE) 5.0 with 2 Mbps. iOS device
                  </Typography>
                </div>

              </div>

            </div>
          </div>

        </Container>
        <Container component="main" maxWidth="false" style={{ width: '80%', paddingTop: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 1 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: '40px', marginBottom: '40px' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '16%' }}>
                <Button
                  variant="contained"
                  onClick={handleOrderDeviceClick}
                  sx={{
                    backgroundColor: '#003A57', // 设置背景颜色为 #003A57
                    color: '#ffffff', // 设置文字颜色为白色
                    fontSize: '18px',
                    boxShadow: 'none', // 去除阴影
                    borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                    padding: '10px 20px', // 添加适当的内边距
                    '&:hover': {
                      backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                      color: '#ffffff', // 悬停时文字颜色仍然为白色
                      boxShadow: 'none', // 确保悬停时也没有阴影
                    },
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                  endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
                >
                  PRE-ORDER NOW
                </Button>
              </div>
            </div>

            <div style={{ flex: 1 }}>

            </div>
            <div style={{ flex: 1 }}></div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={GreenDotCheck}
                  alt="GreenDotCheck"
                  style={{ width: '30px', height: '30px' }}
                />
                <Typography variant="p" component="p" style={{ 
                  // fontFamily: 'Montserrat', 
                  fontWeight: 'Medium', fontSize: '25px', marginLeft: '20px' }}>
                  Heart Health, Blood Oxygen
                  Level & Fall Detection in
                  one device
                </Typography>
              </div>


            </div>
            <div style={{ flex: 1 }}>
              <img
                src={lineLeftUp}
                alt="lineLeftUp"
                style={{ width: '280px', height: '50%', marginBottom: '10px' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '16%' }}>
              <div style={{ maxWidth: '100%', maxHeight: '100%' }}></div>
            </div>

            <div style={{ flex: 1 }}>
              <img
                src={lineRightUp}
                alt="lineRightUp"
                style={{ width: '280px', cursor: 'pointer', marginBottom: '10px' }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="p" component="p" style={{ 
                  // fontFamily: 'Montserrat', 
                  fontWeight: 'Medium', fontSize: '25px', marginRight: '20px' }}>
                  Login portals for Patient
                  Family & Doctors
                </Typography>
                <img
                  src={GreenDotCheck}
                  alt="GreenDotCheck"
                  style={{ width: '30px', height: '30px' }}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={GreenDotCheck}
                  alt="GreenDotCheck"
                  style={{ width: '30px', height: '30px' }}
                />
                <Typography variant="p" component="p" style={{ 
                  // fontFamily: 'Montserrat',
                   fontWeight: 'Medium', fontSize: '25px', marginLeft: '20px' }}>
                  Remote Patient Monitoring
                </Typography>
              </div>

            </div>
            <div style={{ flex: 1 }}>
              <img
                src={lineRight}
                alt="lineRight"
                style={{ width: '280px', cursor: 'pointer', marginBottom: '10px' }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <img
                src={appDevice3IMG}
                alt="App Device"
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            </div>

            <div style={{ flex: 1 }}>
              <img
                src={lineLeft}
                alt="lineLeft"
                style={{ width: '280px', cursor: 'pointer', marginBottom: '10px' }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="p" component="p" style={{ 
                  // fontFamily: 'Montserrat', 
                  fontWeight: 'Medium', fontSize: '25px', marginRight: '20px' }}>
                  Real-time warnings & alerts
                </Typography>
                <img
                  src={GreenDotCheck}
                  alt="GreenDotCheck"
                  style={{ width: '30px', height: '30px' }}
                />
              </div>
            </div>
          </div>

        </Container>
        <div id="Page4" style={{ backgroundImage: `url(${MainBG3})`, height: '100hv', backgroundSize: 'cover', backgroundPosition: 'center center', width: '100%' }}>
          {/* page4 */}
          <div style={{ display: 'flex', width: '80%', margin: '0 auto', }}>
            {/* DIV1 */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '60px' }}>
              {/* DIV2 */}
              <img src={deviceIMG1} alt="deviceIMG1" style={{ width: '120px', height: '120px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* DIV2.1 */}
                <img
                  src={downloadAppleIMG}
                  alt="Download Apple"
                  style={{ width: '300px', cursor: 'pointer', marginTop: '10px' }}
                  onClick={handleDownloadAppleImageClick}
                />

                <img
                  src={downloadGoogleIMG}
                  alt="Download Google"
                  style={{ width: '300px', cursor: 'pointer', marginTop: '10px' }}
                  onClick={handleDownloadGoogleImageClick}
                />
              </div>
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
              {/* 两个Typography组件 */}
              <Typography variant="h1" component="h2" style={{ 
                // fontFamily: 'Montserrat',
                 fontWeight: 'bold', color: 'white', fontSize: '50px', marginBottom: '100px' }}>
                APP BLUETOOTH SYNC
              </Typography>
              <Typography variant="h1" component="h2" style={{ 
                // fontFamily: 'Montserrat',
                 fontWeight: 'bold', color: 'white', fontSize: '30px' }}>
                The wireless blood pressure monitor can be connected to iOS app via Bluetooth where you can view your real-time stats including both blood pressure and ECG data.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default HomePage;
