import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import Ellipse1 from './assets/Ellipse1.png';
import Ellipse2 from './assets/Ellipse2.png';
import Ellipse3 from './assets/Ellipse3.png';
import Ellipse4 from './assets/Ellipse4.png';
import DEVICE2 from './assets/app+device3.png';
import app1 from './assets/app1.png';
import app2 from './assets/app2.png';
import app3 from './assets/app3.png';

import heartHealthBG from './assets/heartHealthBG.png';
import sleepQualityBG from './assets/sleepQualityBG.png';
import activityTrackBG from './assets/activityTrackBG.png';
import fallDetcBG from './assets/fallDetcBG.png';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
// import OrderBG1 from './assets/OrderBG1.png';
function OrderPage() {
    const navigate = useNavigate();
    // const navigate = useNavigate();
    // 在组件加载时，自动滚动到页面顶部
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleShopPageClick = () => {
        navigate('/shoppage');
      };
    return (
        <div>
            <div id="Page0" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '120px', backgroundColor: '#F8F8E2', height: '50vh', paddingLeft: '100px', paddingRight: '100px', paddingTop: '100px' }}>
                    <div style={{ flexBasis: '50%' }}>
                        <Typography paragraph style={{ 
                            // fontFamily: 'Montserrat', 
                            fontWeight: 500, fontSize: '20px', color: '#003A57', textAlign: "left", width: '100%' }}>
                            INTRODUCING VITALMARK
                        </Typography>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                // fontFamily: 'Montserrat',
                                color: '#003A57',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',
                                textTransform: 'uppercase', // 将内容全部大写
                                marginTop: '30px'
                            }}
                        >
                            A personalized view of
                            your whole health
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px', marginTop: '40px', height: '120px' }}>
                            <img
                                src={Ellipse1}
                                alt="Ellipse1"
                            />
                            <img
                                src={Ellipse2}
                                alt="Ellipse2"
                            />
                            <img
                                src={Ellipse3}
                                alt="Ellipse3"
                            />
                            <img
                                src={Ellipse4}
                                alt="Ellipse4"
                            />
                        </div>
                    </div>
                    <div style={{ flexBasis: '30%' }}>
                        <img
                            src={DEVICE2}
                            alt="Ellipse4"
                            style={{
                                width: '20vw'
                            }}
                        />

                    </div>
                </div>
            </div>
            <div id="Page1" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#DDF4FF', height: '50vh', paddingLeft: '100px', paddingRight: '100px', paddingTop: '100px',  backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '60vw center', backgroundImage: `url(${heartHealthBG})` }}>
                    <div style={{ flexBasis: '30%' }}>
                        <img
                            src={app3}
                            alt="Ellipse4"
                            style={{
                                width: '20vw'
                            }}
                        />
                    </div>
                    <div style={{ flexBasis: '50%' }}>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                // fontFamily: 'Montserrat',
                                color: '#003A57',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',
                                textTransform: 'uppercase', // 将内容全部大写
                                marginTop: '30px'
                            }}
                        >
                            HEART HEALTH
                        </Typography>
                        <Typography paragraph style={{ 
                            // fontFamily: 'Montserrat', 
                            fontWeight: 500, fontSize: '20px', color: '#003A57', textAlign: "left", width: '100%' }}>
                            24/7 monitoring, providing continuous Heart Rate, real time ECG and Blood Oxygen Saturation (SpO2) data for valuable health insights.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleShopPageClick}
                            sx={{
                                marginTop: '50px',
                                backgroundColor: '#0091D4', // 设置背景颜色为 #003A57
                                color: '#ffffff', // 设置文字颜色为白色
                                fontSize: '18px',
                                boxShadow: 'none', // 去除阴影
                                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                                padding: '12px 34px', // 添加适当的内边距
                                '&:hover': {
                                    backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                                    color: '#ffffff', // 悬停时文字颜色仍然为白色
                                    boxShadow: 'none', // 确保悬停时也没有阴影
                                },
                                marginLeft: 2,
                                marginRight: 2,
                            }}
                            endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
                        >   
                            Shop Now
                        </Button>
                    </div>

                </div>
            </div>
            <div id="Page2" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#F7EFE5', height: '50vh', paddingLeft: '100px', paddingRight: '100px', paddingTop: '100px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '60vw center', backgroundImage: `url(${sleepQualityBG})` }}>
                    <div style={{ flexBasis: '30%' }}>
                        <img
                            src={app2}
                            alt="Ellipse4"
                            style={{
                                width: '20vw'
                            }}
                        />
                    </div>
                    <div style={{ flexBasis: '50%' }}>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                // fontFamily: 'Montserrat',
                                color: '#003A57',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',
                                textTransform: 'uppercase', // 将内容全部大写
                                marginTop: '30px'
                            }}
                        >
                            SLEEP QUALITY
                        </Typography>
                        <Typography paragraph style={{ 
                            // fontFamily: 'Montserrat', 
                            fontWeight: 500, fontSize: '20px', color: '#003A57', textAlign: "left", width: '100%' }}>
                            Identify ways to get a more restful sleep with detailed analysis of your sleep cycles and overnight health vitals.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleShopPageClick}
                            sx={{
                                marginTop: '50px',
                                backgroundColor: '#0091D4', // 设置背景颜色为 #003A57
                                color: '#ffffff', // 设置文字颜色为白色
                                fontSize: '18px',
                                boxShadow: 'none', // 去除阴影
                                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                                padding: '12px 34px', // 添加适当的内边距
                                '&:hover': {
                                    backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                                    color: '#ffffff', // 悬停时文字颜色仍然为白色
                                    boxShadow: 'none', // 确保悬停时也没有阴影
                                },
                                marginLeft: 2,
                                marginRight: 2,
                            }}
                            endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
                        >
                            Shop Now
                        </Button>
                    </div>

                </div>
            </div>
            <div id="Page3" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#E9FAE2', height: '50vh', paddingLeft: '100px', paddingRight: '100px', paddingTop: '100px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '60vw center', backgroundImage: `url(${activityTrackBG})` }}>
                    <div style={{ flexBasis: '30%' }}>
                        <img
                            src={app1}
                            alt="Ellipse4"
                            style={{
                                width: '20vw'
                            }}
                        />
                    </div>
                    <div style={{ flexBasis: '50%' }}>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                // fontFamily: 'Montserrat',
                                color: '#003A57',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',
                                textTransform: 'uppercase', // 将内容全部大写
                                marginTop: '30px'
                            }}
                        >
                            ACTIVITY TRACKING
                        </Typography>
                        <Typography paragraph style={{ 
                            // fontFamily: 'Montserrat', 
                            fontWeight: 500, fontSize: '20px', color: '#003A57', textAlign: "left", width: '100%' }}>
                            24/7 monitoring of your activity trails, constantly recording steps taken and calories burned, helping you to make better adjustments to your fitness plan.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleShopPageClick}
                            sx={{
                                marginTop: '50px',
                                backgroundColor: '#0091D4', // 设置背景颜色为 #003A57
                                color: '#ffffff', // 设置文字颜色为白色
                                fontSize: '18px',
                                boxShadow: 'none', // 去除阴影
                                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                                padding: '12px 34px', // 添加适当的内边距
                                '&:hover': {
                                    backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                                    color: '#ffffff', // 悬停时文字颜色仍然为白色
                                    boxShadow: 'none', // 确保悬停时也没有阴影
                                },
                                marginLeft: 2,
                                marginRight: 2,
                            }}
                            endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
                        >
                            Shop Now
                        </Button>
                    </div>
                </div>
            </div>
            <div id="Page4" style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#F2F2F2', height: '50vh', paddingLeft: '100px', paddingRight: '100px', paddingTop: '100px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '60vw center', backgroundImage: `url(${fallDetcBG})` }}>
                    <div style={{ flexBasis: '30%' }}>
                        <img
                            src={DEVICE2}
                            alt="Ellipse4"
                            style={{
                                width: '20vw'
                            }}
                        />
                    </div>
                    <div style={{ flexBasis: '50%' }}>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            style={{
                                // fontFamily: 'Montserrat',
                                color: '#003A57',
                                fontWeight: 'bold',
                                fontSize: '4vh',
                                textAlign: 'left',
                                width: '100%',
                                textTransform: 'uppercase', // 将内容全部大写
                                marginTop: '30px'
                            }}
                        >
                            FALL DETECTION
                        </Typography>
                        <Typography paragraph style={{ 
                            // fontFamily: 'Montserrat', 
                            fontWeight: 500, fontSize: '20px', color: '#003A57', textAlign: "left", width: '100%' }}>
                            Gyroscope sensor monitors your body position for fall detection and alert your love ones with real time alert system built in the app.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleShopPageClick}
                            sx={{
                                marginTop: '50px',
                                backgroundColor: '#0091D4', // 设置背景颜色为 #003A57
                                color: '#ffffff', // 设置文字颜色为白色
                                fontSize: '18px',
                                boxShadow: 'none', // 去除阴影
                                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                                padding: '12px 34px', // 添加适当的内边距
                                '&:hover': {
                                    backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                                    color: '#ffffff', // 悬停时文字颜色仍然为白色
                                    boxShadow: 'none', // 确保悬停时也没有阴影
                                },
                                marginLeft: 2,
                                marginRight: 2,
                            }}
                            endIcon={<ArrowForwardIcon style={{ color: '#ffffff' }} />} // 在按钮右侧添加白色箭头图标
                        >
                            Shop Now
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default OrderPage;
