import { getAuth, getIdToken, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { throttle } from 'lodash';
import { DateTime } from 'luxon'; // 引入 luxon
// 定义 ApneaEventType 枚举
export const ApneaEventType = Object.freeze({
  APNEA: "apnea",
  HYPOPNEA: "hypopnea",
  DESATURATION: "desaturation"
});
class NetworkManager {

  // baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:1234';
  baseUrl = process.env.REACT_APP_API_URL || 'https://vitalitusmed.com';
  
  async fetchWithFirebaseToken(path, options = {}) {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true); // 强制刷新令牌

    const response = await fetch(`${this.baseUrl}/${path}`, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  }

  async fetchUserDataAll(path) {
    return this.fetchWithFirebaseToken(path);
  }
  // async fetchUserRecordsWithinTimeRange(userId, startEndTimePair) {
  //   // 构建带有时间参数的查询字符串
  //   const query = `startTime_query=${encodeURIComponent(startEndTimePair[0])}`;
  //   return this.fetchWithFirebaseToken(`users/${userId}/records?${query}`);
  // }
  async fetchUserDataProfile(userId) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    try {
      const response = await fetch(`${this.baseUrl}/users/${userId}/basic`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to fetch data');
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }
  //根据数据库reportTimePairs的数据来获得报告数量及其每个报告的开始和结束时间
  async fetchUserReportTimePairs(userId, searchEndTime) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    const url = new URL(`${this.baseUrl}/users/${userId}/reportTimePairs`);
    // 添加searchStartTime和searchEndTime参数到URL，如果未指定，则不添加
    if (searchEndTime !== undefined) {
      url.searchParams.append('searchEndTime', searchEndTime.toString());
    }

    try {
      const response = await fetch(url.toString(), {
        method: 'GET', // GET请求是默认的，这里显式声明以便清晰
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch report time pairs');
      }

      const data = await response.json(); // 解析响应数据

      if (data.length === 0) {
        // 处理空数组情况，例如可以直接返回空数组或抛出一个错误
        console.log('No report time pairs found');
        return [];
      }

      // 如果需要进一步处理数据，可以在这里进行
      return data; // 返回解析后的数据数组

    } catch (error) {
      console.error('Error fetching report time pairs:', error);
      throw error;
    }
  }
  async deleteUserReportTimePair(userId, startTimeStamp) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    // 构建请求的URL，包括userId和startTimeStamp
    const url = new URL(`${this.baseUrl}/users/${userId}/reportTimePairs/${startTimeStamp}`);

    try {
      const response = await fetch(url.toString(), {
        method: 'DELETE', // 使用DELETE请求
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        // 如果服务器响应的HTTP状态码不是2xx，则抛出错误
        throw new Error('Failed to delete report time pair');
      }

      console.log('Report time pair successfully deleted.');
      return response.json(); // 你可以根据后端的实现，决定是否需要解析响应体
    } catch (error) {
      console.error('Error deleting report time pair:', error);
      throw error; // 将错误抛出，以便调用者处理
    }
  }

  //[{ startTime: 1710846602441, endTime: 1710850022509 }, { startTime: 1710853622509, endTime: 1710857222509 }]
  async fetchUserAudioFilesMetaData(userId, timePairs) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    // 为每个时间对创建一个请求，并且过滤掉返回空数组的响应
    const fetchPromises = timePairs.map(async (pair) => {
      const url = new URL(`${this.baseUrl}/users/${userId}/audioFilesMetaData`);
      url.searchParams.append('startTimeStamp', pair[0].toString());
      url.searchParams.append('endTimeStamp', pair[1].toString());

      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch audio meta data for time pair: ' + JSON.stringify(pair));
      }
      const data = await response.json(); // 返回每个时间对请求的解析结果
      // 将时间对和数据一起返回，以便后续可以决定是否保留结果
      return { data, pair };
    });

    try {
      // 并发执行所有请求
      const allResults = await Promise.all(fetchPromises);

      // 过滤掉那些数据为空的结果
      const filteredResults = allResults.filter(result => result.data.length > 0).map(result => result.data);

      return filteredResults; // 返回过滤后的结果数组，每个元素对应一个有数据的时间对的请求结果


    } catch (error) {
      console.error('Error fetching audio metadata:', error);
      throw error;
    }
  }
  async fetchUserECGFilesMetaData(userId, timePairs) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    // 为每个时间对创建一个请求
    const fetchPromises = timePairs.map(async (pair) => {
      const url = new URL(`${this.baseUrl}/users/${userId}/ECGFilesMetaData`);
      url.searchParams.append('startTimeStamp', pair[0].toString());
      url.searchParams.append('endTimeStamp', pair[1].toString());

      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch ECG meta data for time pair: ' + JSON.stringify(pair));
      }
      return response.json(); // 返回每个时间对请求的解析结果
    });

    try {
      // 并发执行所有请求并等待它们全部完成
      const results = await Promise.all(fetchPromises);
      return results; // 返回一个数组，每个元素对应一个时间对的请求结果
    } catch (error) {
      console.error('Error fetching ECG metadata:', error);
      throw error;
    }
  }
  async fetchUserAiResultFilesMetaData(userId, timePairs) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    // 为每个时间对创建一个请求
    const fetchPromises = timePairs.map(async (pair) => {
      const url = new URL(`${this.baseUrl}/users/${userId}/${pair[0].toString()}-${pair[1].toString()}/AiResultURL`);

      const response = await fetch(url.toString(), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          return { status: 'not_found', data: null }; // 文档未找到
        }
        throw new Error('Failed to fetch AI result URL for time pair: ' + JSON.stringify(pair));
      }

      // 读取响应体
      const text = await response.text();

      // 尝试解析响应为 JSON，如果失败则假设其是一个 URL 字符串
      try {
        const json = JSON.parse(text);
        return json; // 返回解析后的JSON
      } catch (e) {
        return { status: 'success', data: text };
      }
    });

    try {
      // 并发执行所有请求并等待它们全部完成
      const results = await Promise.all(fetchPromises);
      return results; // 返回一个数组，每个元素对应一个时间对的请求结果
    } catch (error) {
      console.error('Error fetching AI result metadata:', error);
      throw error;
    }
  }

  async fetchUserRecordsData(userId, StartEndTimeStampPair) {
    const url = new URL(`${this.baseUrl}/users/${userId}/records`);
    url.searchParams.append('startTimeStamp', StartEndTimeStampPair[0].toString());
    url.searchParams.append('endTimeStamp', StartEndTimeStampPair[1].toString());

    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }
    const token = await getIdToken(user, true); // 强制刷新令牌

    const response = await fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch records data for time range: start=${StartEndTimeStampPair[0]}, end=${StartEndTimeStampPair[1]}`);
    }
    return response.json(); // 返回请求的解析结果
  }
  async fetchMultipleCloudStorageFileFromBackend(userId, fileURLs) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    try {
      // 使用 map 函数将 fileURLs 数组转换为一个 promise 数组
      const fetchPromises = fileURLs.map(fullUrl => {
        const url = new URL(fullUrl);
        let filePath = decodeURIComponent(url.pathname.split('/o/')[1]);

        // 如果你的后端API需要在查询参数中接收编码的路径，则再次对其进行编码
        // 注意：这一步取决于你的后端API如何处理filePath参数
        filePath = encodeURIComponent(filePath);

        return fetch(`${this.baseUrl}/users/${userId}/cloudStorageFile?filePath=${filePath}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            if (response.ok) {
              return response.blob(); // 或者 response.blob() 如果是二进制文件
            } else {
              throw new Error(`Failed to fetch file: ${filePath} from cloud storage`);
            }
          })
      });

      // 使用 Promise.all 等待所有文件的下载完成
      const files = await Promise.all(fetchPromises);
      return files;
    } catch (error) {
      console.error('Error fetching files from cloud storage:', error);
      throw error;
    }
  }
  async fetchMultipleCloudStorageFile(userId, fileURLs, progressCallback) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    try {
      // 获取文件路径
      const filePaths = fileURLs.map(fullUrl => {
        const url = new URL(fullUrl);
        return decodeURIComponent(url.pathname.split('/o/')[1]);
      });

      // 请求预签名URL
      const response = await fetch(`${this.baseUrl}/users/${userId}/getSignedUrls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ filePaths }),
      });

      const textResponse = await response.text(); // 先将响应转为文本

      if (!response.ok) {
        console.error('Error response:', textResponse); // 打印错误响应
        throw new Error('Failed to fetch signed URLs');
      }

      let signedUrls;
      try {
        signedUrls = JSON.parse(textResponse).signedUrls; // 解析为JSON
      } catch (jsonError) {
        console.error('Failed to parse JSON response:', textResponse);
        throw new Error('Failed to parse JSON response');
      }
      const throttledProgressCallback = throttle(progressCallback, 100); // 每 100ms 调用一次
      // 下载所有文件
      const fetchPromises = signedUrls.map(async ({ filePath, url }, index) => {
        try {
          //console.log(`Fetching file from URL: ${url}`);
          const fileResponse = await fetch(url);
          if (!fileResponse.ok) {
            throw new Error(`Failed to download file: ${filePath}`);
          }
          // return fileResponse.blob(); // 返回文件路径、内容和成功状态
          const blob = await fileResponse.blob();
          if (progressCallback) {
            throttledProgressCallback((index + 1) / signedUrls.length);
          }
          return blob;
        } catch (fetchError) {
          console.error(`Error fetching file from URL ${url}:`, fetchError);
          return null; // 返回文件路径、错误和失败状态
        }
      });

      // 使用 Promise.all 等待所有文件的下载完成
      const files = await Promise.all(fetchPromises);
      return files;
    } catch (error) {
      console.error('Error fetching files from cloud storage:', error);
      throw error;
    }
  }

  async fetchCloudStorageFile(userId, filePath) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    try {
      const response = await fetch(`${this.baseUrl}/users/${userId}/cloudStorageFile?filePath=${encodeURIComponent(filePath)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const fileContents = await response.blob();// 或者 response.blob() 如果是二进制文件
        return fileContents;
      } else {
        throw new Error('Failed to fetch file from cloud storage');
      }
    } catch (error) {
      console.error('Error fetching file from cloud storage:', error);
      throw error;
    }
  }

  async fetchCloudStorageTxtFile(userId, folderPath) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true);

    try {
      const response = await fetch(`${this.baseUrl}/users/${userId}/cloudStorageTxtFile?folderPath=${encodeURIComponent(folderPath)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const txtFileContents = await response.text();
        return txtFileContents;
      } else {
        throw new Error('Failed to fetch TXT file from cloud storage');
      }
    } catch (error) {
      console.error('Error fetching TXT file from cloud storage:', error);
      throw error;
    }
  }


  async registerUser(userData) {
    try {
      // 在 Firebase Auth 注册用户
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, userData.email, userData.password);
      const user = userCredential.user;

      // 获取 Firebase Auth 的 ID 令牌
      const token = await user.getIdToken();
      // 从发送到后端的数据中移除密码
      const { password, ...userDataWithoutPassword } = userData;
      // 构造请求头部
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      // 发送请求到后端
      const response = await fetch(`${this.baseUrl}/users`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          ...userDataWithoutPassword,
          uid: user.uid
        })
      });

      if (response.ok) {
        // 解析响应数据
        const data = await response.json();
        await signInWithEmailAndPassword(auth, userData.email, userData.password);
        return data;
      } else {
        // 处理错误情况
        const errorData = await response.json();
        throw new Error(errorData.error || 'Registration failed');
      }
    } catch (error) {
      // 错误处理
      throw new Error('Registration failed: ' + error.message);
    }
  }
  // async updateUserProfile(uid, editedProfile) {
  //   try {
  //     // 获取当前认证用户的 ID 令牌
  //     const auth = getAuth();
  //     const user = auth.currentUser;
  //     const token = await user.getIdToken();

  //     // 构造请求头部
  //     const headers = {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     };

  //     // 发送请求到后端
  //     const response = await fetch(`${this.baseUrl}/edituser/${uid}`, {
  //       method: 'POST', // 可以是 POST 或 PUT，根据你的 API 设计
  //       headers: headers,
  //       body: JSON.stringify(editedProfile) // 将编辑后的用户信息作为请求体
  //     });

  //     if (response.ok) {
  //       // 解析响应数据
  //       const data = await response.json();
  //       return data; // 返回更新后的用户数据
  //     } else {
  //       // 处理错误情况
  //       const errorData = await response.json();
  //       const error = new Error(errorData.errorMessages || 'An error occurred'); // 创建 Error 对象
  //       error.errorLabelIDs = errorData.errorLabelIDs; // 将 errorLabelIDs 添加到 Error 对象
  //       throw error; // 抛出 Error 对象
  //     }
  //   } catch (error) {
  //     // 错误处理
  //     throw error;
  //   }
  // }
  async updateUserProfile(uid, editedProfile) {
    try {
      // 获取当前认证用户的 ID 令牌
      const auth = getAuth();
      const user = auth.currentUser;
      const token = await user.getIdToken();

      // 构造请求头部
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      // 处理 birthDate 的转换
      const birthDate = editedProfile.birthDate;
      const birthTimeZone = editedProfile.birthTimeZone || 'America/Los_Angeles'; // 默认时区

      let formattedBirthDate;
      if (birthDate && typeof birthDate._seconds === 'number') {
        // 使用 luxon 将 Firebase 时间戳转换为指定时区的日期字符串
        const dateInTimeZone = DateTime.fromSeconds(birthDate._seconds)
          .setZone(birthTimeZone)
          .toFormat('yyyy-MM-dd'); // 格式化为 'yyyy-MM-dd'

        // 更新 birthDate 为字符串格式
        formattedBirthDate = dateInTimeZone;
      } else {
        formattedBirthDate = null; // 如果 birthDate 不存在，设为 null
      }

      // 发送请求到后端，覆盖 birthDate 为字符串
      const updatedProfile = {
        ...editedProfile,
        birthDate: formattedBirthDate // 用字符串替换原来的时间戳对象
      };

      // 发送请求到后端
      const response = await fetch(`${this.baseUrl}/edituser/${uid}`, {
        method: 'POST', // 可以是 POST 或 PUT，根据你的 API 设计
        headers: headers,
        body: JSON.stringify(updatedProfile) // 将编辑后的用户信息作为请求体
      });

      if (response.ok) {
        // 解析响应数据
        const data = await response.json();
        return data; // 返回更新后的用户数据
      } else {
        // 处理错误情况
        const errorData = await response.json();
        const error = new Error(errorData.errorMessages || 'An error occurred'); // 创建 Error 对象
        error.errorLabelIDs = errorData.errorLabelIDs; // 将 errorLabelIDs 添加到 Error 对象
        throw error; // 抛出 Error 对象
      }
    } catch (error) {
      // 错误处理
      throw error;
    }
  }
  async deleteAccount(path) {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error('User not authenticated');
    }

    const token = await getIdToken(user, true); // 强制刷新令牌
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await fetch(
      `${this.baseUrl}/${path}`,
      {
        method: 'DELETE',
        headers: headers,
      }
    );

    return response.status;
  }

  async fetchUserRelatedPatients(userId) {
    try {
      // 获取当前用户
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      // 获取 ID 令牌并强制刷新
      const token = await getIdToken(user, true);

      // 设置请求头，包括授权头和内容类型
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      // 构造请求 URL
      const url = `${this.baseUrl}/users/${userId}/userPatients`;

      // 使用 fetch 发送 GET 请求
      const response = await fetch(url, {
        method: 'GET',
        headers: headers,
      });

      // 检查响应状态
      if (!response.ok) {
        throw new Error(`Error fetching related patients: ${response.statusText}`);
      }

      // 解析 JSON 响应体
      const data = await response.json();

      // 假设返回的数据格式为 { patients: [...] }
      return data.patients;
    } catch (error) {
      console.error('Error fetching related patients:', error);
      throw error; // 重新抛出错误，以便调用者可以处理
    }
  }
  async generateApneaIMG(userId, apneaEvents) {
    try {
      // 获取当前用户
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        throw new Error('User not authenticated');
      }

      // 获取 ID 令牌并强制刷新
      const token = await getIdToken(user, true);

      // 设置请求头，包括授权头和内容类型
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      // 构造请求 URL
      const url = `${this.baseUrl}/users/${userId}/generate-apnea-image`;

      // 使用 fetch 发送 POST 请求，传递 apneaEvents 数据
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(apneaEvents)
      });

      // 检查响应状态
      if (!response.ok) {
        throw new Error(`Error generating apnea image: ${response.statusText}`);
      }

      // 将响应内容作为 ArrayBuffer 获取（PNG 图像的二进制数据）
      const arrayBuffer = await response.arrayBuffer();

      // 将 ArrayBuffer 转换为 Blob 对象
      const imageBlob = new Blob([arrayBuffer], { type: 'image/png' });

      // 创建一个 URL 对象用于在前端显示 PNG 图片
      const imageUrl = window.URL.createObjectURL(imageBlob);

      return { imageBlob, imageUrl }; // 返回图像 Blob 和 URL
    } catch (error) {
      console.error('Error generating apnea image:', error);
      throw error; // 重新抛出错误，以便调用者可以处理
    }
  }
}
// 创建一个 NetworkManager 实例并赋值给一个变量
const networkManagerInstance = new NetworkManager();

// 然后导出这个变量
export default networkManagerInstance;
