import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent, LinearProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext'; // 导入 UserContext
import NetworkManager from './NetworkManager';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const AudioVisualizer = React.lazy(() => import('./AudioVisualizer'));
const ECGVisualizer = React.lazy(() => import('./ECGVisualizer'));
const SignalVisualizer = React.lazy(() => import('./SignalVisualizer'));
function RawDataPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const reportTimePair = location.state?.reportTimePair;
    const { currentUser, isSuperAdmin, adminSearchUserId, generatedAudioReport, setGeneratedAudioReport } = useContext(UserContext);
    const prevCurrentUser = useRef();
    const prevreportTimePair = useRef();
    const [isFechingRecords, setIsFechingRecords] = useState(true);
    const [progress, setProgress] = useState(0); // 跟踪进度
    const [error, setError] = useState(null);
    const audioVisualizerRef = useRef();
    const ecgVisualizerRef = useRef();
    const signalVisualizerRef1 = useRef();
    const signalVisualizerRef2 = useRef();
    const signalVisualizerRef3 = useRef();
    const signalVisualizerRef4 = useRef();
    const [zoomRange] = useState({ startTime: null, endTime: null });
    const handleZoomRangeUpdate = (newRange) => {
        console.log("Zoom range updated: in superclass" );
        // setZoomRange({ ...newRange });  // 更新 zoom 范围
    };
    // const handleECGClearCanvas = () => {
    //     if (ecgVisualizerRef.current) {
    //       ecgVisualizerRef.current.clearCanvas();
    //     }
    //   };
    
    //   const handleECGRedraw = () => {
    //     if (ecgVisualizerRef.current) {
    //       ecgVisualizerRef.current.redraw();
    //     }
    //   };
    const handleSharedIndexChange = (index) => {
        // 使用 index 触发其他子组件的事件

        if (signalVisualizerRef1.current) {
            // console.log("Shared index changed in signalVisualizerRef1:", index);
            signalVisualizerRef1.current.triggerMouseMove_ValueChart(index);
        }
        if (signalVisualizerRef2.current) {
            // console.log("Shared index changed in signalVisualizerRef2:", index);
            signalVisualizerRef2.current.triggerMouseMove_ValueChart(index);
        }
        if (signalVisualizerRef3.current) {
            // console.log("Shared index changed in signalVisualizerRef3:", index);
            signalVisualizerRef3.current.triggerMouseMove_ValueChart(index);
        }
        if (signalVisualizerRef4.current) {
            //  console.log("Shared index changed in signalVisualizerRef4:", index);
            signalVisualizerRef4.current.triggerMouseMove_ValueChart(index);
        }
    };


    const handleBack = () => {
        navigate(-1); // 导航回上一个页面
    };
    const moveLeft = () => {
        audioVisualizerRef.current.moveLeft(10)
    };
    const moveRight = () => {
        audioVisualizerRef.current.moveRight(10)
    };
    useEffect(() => {
        if (currentUser?.uid !== prevCurrentUser.current?.uid || reportTimePair !== prevreportTimePair.current) {
            console.log('[RawDataPage] Effect running for:', { currentUser, reportTimePair: reportTimePair });
            if (!currentUser) {
                setError('user is not log in')
                return
            }
            // 更新引用
            prevCurrentUser.current = currentUser;
            prevreportTimePair.current = reportTimePair;
            setIsFechingRecords(true);
            setProgress(0);
            // 确定要使用的用户ID
            const targetUserId = isSuperAdmin && adminSearchUserId ? adminSearchUserId : currentUser.uid;
            let overallProgress = { wav: 0, ecg: 0, records: 0, txt: 0 };
            const updateOverallProgress = (task, taskProgress) => {
                overallProgress[task] = taskProgress;
                const totalProgress = (overallProgress.wav + overallProgress.ecg + overallProgress.records + overallProgress.txt) / 3;
                //console.log(`Task: ${task}, Task Progress: ${taskProgress}, Overall Progress: ${totalProgress}`);
                setProgress(Math.min(totalProgress * 100, 100)); // 确保进度不超过 100%
            };

            const fetchWAVFilesPromise = NetworkManager.fetchUserAudioFilesMetaData(targetUserId, [reportTimePair])
                .then(audioReportMetaData => {
                    if (audioReportMetaData.length > 0) {
                        const urls = audioReportMetaData[0].map(meta => meta.downloadURL);
                        return NetworkManager.fetchMultipleCloudStorageFile(targetUserId, urls, (fileProgress) => {
                            updateOverallProgress('wav', fileProgress);
                        });
                    } else {
                        console.log('audioReportMetaData is empty');
                        return null; // 或者 return []; 根据需要选择适当的返回值
                    }
                });

            const fetchECGFilesPromise = NetworkManager.fetchUserECGFilesMetaData(targetUserId, [reportTimePair])
                .then(ECGReportMetaData => {
                    if (ECGReportMetaData.length > 0) {
                        const urls = ECGReportMetaData[0].map(meta => meta.downloadURL);
                        return NetworkManager.fetchMultipleCloudStorageFile(targetUserId, urls, (fileProgress) => {
                            updateOverallProgress('ecg', fileProgress);
                        });
                    } else {
                        console.log('ECGReportMetaData is empty');
                        return null; // 或者 return []; 根据需要选择适当的返回值
                    }

                });
            const fetchAiResultPromise = NetworkManager.fetchUserAiResultFilesMetaData(targetUserId, [reportTimePair])
                .then(results => {
                    const result = results[0]; // 因为我们只查询了一个时间对
                    if (result.status === 'not_found') {
                        console.log('AI result not found for time pair');
                        throw new Error('AI result not found for time pair');
                    } else if (result.status === 'processing') {
                        console.log('AI result is still processing');
                        return null;
                    } else if (result.status === 'success') {
                        const AiResultURL = result.data;
                        console.log('AI result URL:', AiResultURL);
                        return fetch(AiResultURL)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Failed to fetch AI result file from cloud storage');
                                }
                                return response.text(); // 将内容解析为文本
                            })
                            .then(fileContents => {
                                // 打印下载的文件内容
                                console.log('Downloaded file contents:', fileContents);
                                // 如果需要更新进度，可以在这里调用 updateOverallProgress
                                updateOverallProgress('txt', 100); // 假设下载完成，进度更新为100%
                                return fileContents; // 返回文件内容
                            });
                    } else {
                        throw new Error('Unexpected status in AI result response');
                    }
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                });
            const fetchRecordFilesPromise = NetworkManager.fetchUserRecordsData((adminSearchUserId == null || adminSearchUserId.length === 0) ? currentUser.uid : adminSearchUserId, reportTimePair)
                .then(records => {
                    updateOverallProgress('records', 1);
                    return records;
                })
                .catch(error => {
                    console.log('Error fetching records:', error);
                    return 'error';
                });
            // 使用Promise.all等待两个文件下载操作都完成
            Promise.all([fetchWAVFilesPromise, fetchECGFilesPromise, fetchRecordFilesPromise, fetchAiResultPromise])
                .then(([wavFiles, ecgFiles, filteredRecords, aiAudioReseult]) => {
                    console.log('RECORDS WAV airesult and ECG files have been downloaded successfully.');

                    // 此时直接使用 wavFiles, ecgFiles, records，因为它们是Promise返回的最新值
                    const startTime_query = reportTimePair[0];
                    const endTime_query = reportTimePair[1];
                    const generatedReport = {
                        reportStartTime: startTime_query,
                        reportEndTime: endTime_query,
                        PCM_Files: wavFiles,   // WAV 文件内容，直接使用Promise的结果
                        ECG_Files: ecgFiles,   // ECG 文件内容，直接使用Promise的结果
                        AiAudioResult: aiAudioReseult,//audio result breath number 1715968218-1715969393.txt
                        Data_Records: filteredRecords // 筛选后的记录，直接使用Promise的结果
                    };

                    // 使用 setGeneratedAudioReport 更新上下文中的 generatedAudioReport
                    setGeneratedAudioReport(generatedReport);

                    setError(null);
                    //setECGFileDownloaded(true);
                    //setWavFileDownloaded(true);
                    setIsFechingRecords(false);
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                    setError('Error downloading files:', error);
                    setIsFechingRecords(false);
                });




        }
    }, [currentUser, reportTimePair, setGeneratedAudioReport, adminSearchUserId, isSuperAdmin]);

    return (
        <div style={{ marginTop: '217px' }}>
            <Container component="main" maxWidth="false" style={{ padding: '20px' }}>
                {/* 添加 Back 按钮 */}
                <Button
                    onClick={handleBack}
                    variant="contained"
                    color="primary"
                    style={{
                        margin: '20px',
                        backgroundColor: '#1976d2',
                        color: 'white',
                        fontSize: 'larger'
                    }}
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button>
                {/* 病人简历 Section */}
                {isFechingRecords && <LinearProgress variant="determinate" value={progress} />}
                <Card style={{ marginBottom: '20px', backgroundColor: '#f8f0ff' }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Raw Data records
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                Voice Data
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                    {/* left move 按钮 */}
                                                    <Button onClick={() => moveLeft()} variant="outlined" color="primary">
                                                        《
                                                    </Button>

                                                </div>
                                                <div style={{ width: '100%', padding: '30px' }}>
                                                    <AudioVisualizer ref={audioVisualizerRef} generatedReport={generatedAudioReport} onZoomRangeUpdate={handleZoomRangeUpdate} />
                                                </div> 

                                                {/* Buttons */}
                                                <div>
                                                    {/* right move 按钮 */}
                                                    <Button onClick={() => moveRight()} variant="outlined" color="primary">
                                                        》
                                                    </Button>

                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                ECG Data
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div>
                                                </div>
                                                <div style={{ width: '92.2%', paddingTop: '20px', paddingBottom : '20px',paddingLeft:'40px',paddingRight:'40px' }}>
                                                    <ECGVisualizer ref={ecgVisualizerRef} generatedReport={generatedAudioReport} />
                                                </div>
                                                <div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                Heart Rate
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ width: '92.2%', padding: '40px' }}>
                                                    <SignalVisualizer ref={signalVisualizerRef1} generatedReport={generatedAudioReport} zoomRange={zoomRange}  render="heartRPM" onSharedIndexChange={handleSharedIndexChange} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                SpO2
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ width: '92.2%', padding: '40px' }}>
                                                    <SignalVisualizer ref={signalVisualizerRef2} generatedReport={generatedAudioReport} render="SPO2" onSharedIndexChange={handleSharedIndexChange} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                Body Temperature
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ width: '92.2%', padding: '40px' }}>
                                                    <SignalVisualizer ref={signalVisualizerRef3} generatedReport={generatedAudioReport} render="TempC" onSharedIndexChange={handleSharedIndexChange} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer>

                        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                Body Oriantation
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ width: '92.2%', padding: '40px' }}>
                                                    <SignalVisualizer ref={signalVisualizerRef4} generatedReport={generatedAudioReport} render="activity" onSharedIndexChange={handleSharedIndexChange} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer> */}
                        <div style={{ display: 'flex', alignItems: 'center', gap: '400px' }}>
                            <Typography variant="h2" component="h3" gutterBottom style={{
                                // fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                textAlign: "left",
                                color: '#0db067',
                                margin: '20px'
                            }}>

                                Body Position
                            </Typography>

                        </div>
                        <TableContainer component={Paper} style={{ width: '100%', marginBottom: '20px', backgroundColor: '#fff' }}>
                            {isFechingRecords ? (
                                <p>Loading...</p>
                            ) : error ? (
                                <p>Error: {error}</p>
                            ) : generatedAudioReport ? (
                                <Table aria-label="Raw data preview">
                                    <TableHead>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ width: '92.2%', padding: '40px' }}>
                                                    <SignalVisualizer ref={signalVisualizerRef4} generatedReport={generatedAudioReport} render="bodyOrient" onSharedIndexChange={handleSharedIndexChange} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            ) : (
                                <p>No user data available.</p>
                            )}
                        </TableContainer>
                    </CardContent>
                </Card>
            </Container>

        </div>

    );
}

export default RawDataPage;
