import React, { useState, useEffect } from 'react';
import { Typography, Button, TextField, IconButton, Snackbar, Alert } from '@mui/material';
import vitalmarkWhite from './assets/vitalmarkWhite.png';
import ACTIVITY_TRACKING_black from './assets/ACTIVITY_TRACKING_black_for_round.svg';
import FALL_DETECTION_black from './assets/FALL_DETECTION_black.svg';
import HEART_HEALTH_black from './assets/HEART_HEALTH_black.svg';
import SLEEP_QUALITY_black from './assets/SLEEP_QUALITY_black.svg';
import AddIcon from '@mui/icons-material/AddBox';
import RemoveIcon from '@mui/icons-material/IndeterminateCheckBox';
import Cart from './cart'; // 导入购物车类
const ShopPage = () => {
  const [cart, setCart] = useState(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));

    if (savedCart) {
      // 使用 Object.create 恢复 Cart 的原型链
      const newCart = Object.create(Cart.prototype);
      Object.assign(newCart, savedCart); // 将 savedCart 数据分配到新的 Cart 实例中
      return newCart;
    } else {
      return new Cart(); // 如果没有保存的购物车，创建新的 Cart 实例
    }
  });
  const product = {
    sku: 1,
    name: 'Vital Mark',
    shipping: 'Calculated at Checkout',
    priceUSD: 188.53,
    priceCAD: 188.53,
    image: vitalmarkWhite,
  };
  const [quantityToBuy, setQuantityToBuy] = useState(1); // 初始化数量为 1
  const [totalQuantity, setTotalQuantity] = useState(0); // 购物车总数量
  const [openSnackbar, setOpenSnackbar] = useState(false); // 控制 Snackbar 提示
  useEffect(() => {
    // 每当 cart 发生变化时，更新 localStorage
    localStorage.setItem('cart', JSON.stringify(cart));
    setTotalQuantity(cart.getTotalQuantity());
  }, [cart]);
  // const handleAddToCart = () => {
  //   const itemInCart = cart.find((item) => item.id === product.id);
  //   if (itemInCart) {
  //     setCart(
  //       cart.map((item) =>
  //         item.id === product.id
  //           ? { ...item, quantity: item.quantity + quantity }
  //           : item
  //       )
  //     );
  //   } else {
  //     setCart([...cart, { ...product, quantity }]);
  //   }
  // };
  const handleAddToCart = () => {
    // 创建购物车的一个浅拷贝
    const newCart = Object.create(Cart.prototype);
    Object.assign(newCart, cart); // 复制现有的购物车数据
  
    // 添加商品到购物车
    newCart.addItem(product, quantityToBuy);
  
    // 触发 React 状态更新
    setCart(newCart);
    setOpenSnackbar(true); // 打开提示
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // 关闭 Snackbar 提示
  };

  return (
    <div id="Page0" style={{ width: '100%' }}>
      <div style={{ display: 'flex', height: '83vh', justifyContent: 'space-between', marginTop: '120px', backgroundColor: '#DDF4FF' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '88%', height: '74%', margin: 'auto' }}>
          <div
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              marginLeft: 'auto',
              backgroundColor: 'white',
              width: '45%',
              aspectRatio: '1/1',  // 保持 1:1 的宽高比
              maxWidth: '50vh',   // 保证容器不会超过视口的高度
              maxHeight: '50vw',  // 保证容器不会超过视口的宽度
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '2px solid #adadac', // 添加边框
            }}
          >
            <img
              src={product.image}
              alt={product.name}
              style={{
                marginTop: 'auto',
                aspectRatio: '1/1',
              }}
            />
            <div style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              height: '60px',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px'
            }}>
              <img
                src={HEART_HEALTH_black}
                alt={'HEART_HEALTH_black'}
                style={{
                  aspectRatio: '1/1',
                  width: '60px',
                }}
              />
              <img
                src={SLEEP_QUALITY_black}
                alt={'SLEEP_QUALITY_black'}
                style={{
                  aspectRatio: '1/1',
                  width: '60px',
                }}
              />
              <img
                src={ACTIVITY_TRACKING_black}
                alt={'ACTIVITYTRACKING_black'}
                style={{
                  aspectRatio: '1/1',
                  width: '60px',
                }}
              />
              <img
                src={FALL_DETECTION_black}
                alt={'FALL_DETECTION_black'}
                style={{
                  aspectRatio: '1/1',
                  width: '60px',
                }}
              />


            </div>
          </div>

          <div style={{
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: 'auto',
            width: '45%',
            maxHeight: '50vw',  // 保证容器不会超过视口的宽度

          }}>

            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              style={{
                color: '#747474',
                fontWeight: 'bold',
                fontSize: '7vh',
                textAlign: 'left',
                width: '100%',

              }}
            >
              {product.name}
            </Typography>
            {/* 分割线 */}
            <hr style={{
              width: '100%',
              border: 'none',
              borderTop: '2px solid #adadac',  // 定制分割线样式
              margin: '0px 0'
            }} />
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              style={{
                color: '#747474',
                fontWeight: 'bold',
                fontSize: '4vh',
                textAlign: 'left',
                width: '100%',

              }}
            >
              Price: ${product.priceCAD}
            </Typography>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              style={{
                color: '#747474',
                fontWeight: 'bold',
                fontSize: '4vh',
                textAlign: 'left',
                width: '100%',

              }}
            >
              SKU: {product.sku}
            </Typography>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                style={{
                  color: '#747474',
                  fontWeight: 'bold',
                  fontSize: '4vh',
                  textAlign: 'left',
                  width: '100%',

                }}
              >
                Quantity:
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* 减号按钮 */}
                <IconButton
                  color="primary"
                  onClick={() => setQuantityToBuy((prev) => Math.max(prev - 1, 0))}
                >
                  <RemoveIcon />
                </IconButton>

                {/* 数量输入框 */}
                <TextField
                  type="number"
                  value={quantityToBuy}
                  onChange={(e) => {
                    const value = Math.max(0, Math.min(Number(e.target.value), 10000)); // 限制值在 0 到 10000 之间
                    setQuantityToBuy(value);
                  }}
                  onFocus={(e) => e.target.select()}  // 当用户点击输入框时，选中当前值，便于输入新值
                  inputProps={{
                    min: 0,
                    max: 10000, // 设置最大值为 10000
                    style: { textAlign: 'center' },
                    inputMode: 'numeric',  // 优化移动设备体验
                    pattern: '[0-9]*',     // 仅允许数字输入
                    sx: {
                      // 禁用数字输入框的箭头
                      '&::-webkit-outer-spin-button': {
                        display: 'none',
                      },
                      '&::-webkit-inner-spin-button': {
                        display: 'none',
                      },
                      // Firefox 的禁用样式
                      '-moz-appearance': 'textfield',
                    },
                  }}
                  style={{ width: '150px' }}
                />

                {/* 加号按钮 */}
                <IconButton
                  color="primary"
                  onClick={() => setQuantityToBuy((prev) => prev + 1)}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            <Button
              onClick={handleAddToCart}
              variant="contained"
              sx={{
                marginLeft: 'auto',
                marginRight: 'auto',
                flexBasis: '30%',
                width: '62%',
                backgroundColor: '#0192d8', // 设置背景颜色为 #003A57
                color: '#ffffff', // 设置文字颜色为白色
                fontSize: '18px',
                boxShadow: 'none', // 去除阴影
                borderRadius: '50px', // 设置圆角，50px 可以使左右两端都呈现圆形
                padding: '12px 34px', // 添加适当的内边距
                '&:hover': {
                  backgroundColor: '#00507A', // 悬停状态的背景颜色，稍微深一点
                  color: '#ffffff', // 悬停时文字颜色仍然为白色
                  boxShadow: 'none', // 确保悬停时也没有阴影
                },
              }}
              disabled={quantityToBuy === 0} // 当数量为 0 时禁用按钮
            >
              Add to Cart
            </Button>
            {/* 提示：已添加到购物车 */}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000} // 3 秒后自动隐藏
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity="success">
                Added to cart! Total items in cart: {totalQuantity}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ShopPage;
