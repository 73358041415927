class Cart {
    constructor() {
        this.items = []; // 储存购物车中的商品
    }

    // 添加商品到购物车
    addItem(product, quantity) {
        const existingItem = this.items.find(item => item.product.sku === product.sku);
        if (existingItem) {
            // 如果商品已经存在，增加商品数量
            existingItem.quantity += quantity;
        } else {
            // 如果商品不存在，添加新的商品
            this.items.push({ product, quantity });
        }
    }
    // 计算购物车中所有商品的总数量
    getTotalQuantity() {
        return this.items.reduce((total, item) => total + item.quantity, 0);
    }

    // 从购物车中删除商品
    removeItem(sku) {
        this.items = this.items.filter(item => item.product.sku !== sku);
    }

    // 更新购物车中特定商品的数量
    updateQuantity(sku, quantity) {
        const item = this.items.find(item => item.product.sku === sku);
        if (item) {
            item.quantity = quantity;
        }
    }

    // 清空购物车
    clearCart() {
        this.items = [];
    }

    // 获取购物车总价
    getTotalPrice() {
        return this.items.reduce((total, item) => {
            return total + item.product.priceCAD * item.quantity;
        }, 0);
    }

    // 查看购物车内容
    getCartItems() {
        return this.items;
    }
}
export default Cart;
